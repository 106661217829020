.main{
    overflow: hidden;
}

.side_social_icon{
    position: fixed;
    right: 0;
    top: 40%;
    background-color: #e4e4e4;
    z-index: 99;
}

.side_social_icon i{
    color: #0d6efd;
    font-size: 1rem;
    padding: 4px;
}

@media screen and (max-width : 786px) {
    .side_social_icon{
        position: fixed;
        right: 5%;
        top: 40%;
        background-color: #e4e4e4;
        z-index: 99;
    }
}